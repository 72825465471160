import { Layout, Theme } from "@life-without-barriers/react-components"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import Footer from "./Footer"
import Header from "./Header"
import React from "react"

const { disabilityTheme } = Theme

interface Props {
  children: React.ReactNode
  fixedCTAPhoneNumber?: string
  fixedCTAContact?: React.ReactElement
}

const phoneNumber = "1800 610 699"
const dialogContent = <DisabilityForm formId="disability-contact-fixed-cta" />

const DisabilityLayout = ({
  children,
  fixedCTAPhoneNumber,
  fixedCTAContact
}: Props) => (
  <Layout
    theme={disabilityTheme}
    header={<Header />}
    footer={<Footer />}
    showSecondaryNav={false}
    fixedCTAPhoneNumber={
      fixedCTAPhoneNumber ? fixedCTAPhoneNumber : phoneNumber
    }
    fixedCTAContact={fixedCTAContact ? fixedCTAContact : dialogContent}
  >
    {children}
  </Layout>
)

export default DisabilityLayout
