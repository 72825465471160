import { Footer } from "@life-without-barriers/react-components"
import React from "react"

const footerLinks = [
  {
    text: "Disability home",
    url: "/disability/"
  },
  {
    text: "Disability services",
    url: "/disability/services/"
  },
  {
    text: "Daily living skills",
    url: "/disability/services/daily-living-skills/"
  },
  {
    text: "Supported independent living",
    url: "/disability/services/supported-independent-living/"
  },
  {
    text: "Health & wellbeing",
    url: "/disability/services/health-and-wellbeing/"
  },
  {
    text: "Support coordination",
    url: "/disability/services/support-coordination/"
  },
  {
    text: "Our approach",
    url: "/disability/our-approach/"
  },
  {
    text: "NDIS",
    url: "/disability/ndis/"
  },
  {
    text: "Careers",
    url: "/disability/careers/"
  },
  {
    text: "Royal Commission",
    url: "/disability/disability-royal-commission/"
  },
  {
    text: "Policy centre",
    url: "/about-us/governance/policy-centre/"
  },
  {
    text: "Privacy policy",
    url: "/about-us/governance/"
  },
  {
    text: "Contact us",
    url: "/disability/contact-us/"
  },
  {
    text: "Digital Accessibility Statement",
    url: "/about-us/governance/digital-accessibility-statement"
  }
]

const DisabilityFooter = () => (
  <Footer
    emailAddress="YourLWB@lwb.org.au"
    links={footerLinks}
    showNdisElements={true}
  />
)

export default DisabilityFooter
