import React from "react"
import { Header } from "@life-without-barriers/react-components"

import disabilityMenu from "./menu"

const DisabilityHeader = () => (
  <Header menuItems={disabilityMenu} showSecondaryNav={false} />
)

export default DisabilityHeader
