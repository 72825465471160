import { MenuItem } from "@life-without-barriers/react-components"

const disabilityMenu: MenuItem[] = [
  {
    kind: "submenu",
    title: "Services",
    items: [
      {
        kind: "link",
        title: "All disability services",
        to: "/disability/services/"
      },
      {
        kind: "link",
        title: "Behaviour support",
        to: "/disability/services/bis/"
      },
      {
        kind: "link",
        title: "Daily living skills",
        to: "/disability/services/daily-living-skills/"
      },
      {
        kind: "link",
        title: "Health & wellbeing",
        to: "/disability/services/health-and-wellbeing/"
      },
      {
        kind: "link",
        title: "Short term ​accommodation",
        to: "/disability/services/short-term-accommodation/"
      },
      {
        kind: "link",
        title: "Social and community support",
        to: "/disability/services/social-and-community-participation/"
      },
      {
        kind: "link",
        title: "Support coordination",
        to: "/disability/services/support-coordination/"
      },
      {
        kind: "link",
        title: "Supported independent living",
        to: "/disability/services/supported-independent-living/"
      },
      {
        kind: "link",
        title: "Employment pathways",
        to: "/disability/services/employment-pathways/"
      }
    ]
  },
  {
    kind: "submenu",
    title: "Our approach",
    items: [
      {
        kind: "link",
        title: "Our approach",
        to: "/disability/our-approach/"
      },
      {
        kind: "link",
        title: "Your rights",
        to: "/disability/our-approach/your-rights/"
      },
      {
        kind: "link",
        title: "Disability Services Advisory Council",
        to: "/disability/our-approach/disability-services-advisory-council/"
      }
    ]
  },
  {
    kind: "link",
    title: "NDIS",
    to: "/disability/ndis/"
  },
  {
    kind: "link",
    title: "Careers",
    to: "/disability/careers/"
  },
  {
    kind: "submenu",
    title: "Contact us",
    items: [
      {
        kind: "link",
        title: "Contact us",
        to: "/disability/contact-us/"
      },
      {
        kind: "link",
        title: "Complaints or feedback",
        to: "/contact-us/complaints-or-feedback/",
        rooted: true
      },
      {
        kind: "link",
        title: "Request for record or information",
        to: "/contact-us/request-for-records"
      }
    ]
  }
]

export default disabilityMenu
