import {
  Helmet,
  ContactBox,
  ContactUsPhoneLink,
  ContactUsEmailLink,
  Banner,
  Box,
  Container,
  Heading,
  Row,
  routesObject
} from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import Layout from "../../../components/disability/Layout"
import { DisabilityForm } from "@life-without-barriers/shared-contact-form"

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  }
]

const ContactContents = () => (
  <ContactBox>
    <p>
      Get in touch for more information about accessing our Disability Host
      Carer services or becoming a Host Carer.
    </p>
    <ContactUsPhoneLink phoneNumber="0892083400" />
    <ContactUsEmailLink
      text="CarerSupportWA@lwb.org.au"
      link="mailto:CarerSupportWA@lwb.org.au"
    />
  </ContactBox>
)

const DisabilityHostCare = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Disability Host Care | ${siteMetadata.title}`}
        description="Disability Host Care is a Western Australia initiative that places a person living with disability with a voluntary family or Host Carer."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout>
        <Banner
          title="Disability Host Care"
          breadcrumbs={bannerBreadcrumbs}
          image={bannerImage}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>What is Host Care?</Heading>
              <p>
                Disability Host Care is a Western Australia initiative that
                places a person living with disability with a voluntary family
                or Host Carer. The Host Carer or family provide 24/7 care and
                support either in their own home or in the home of the person
                living with disability.
              </p>
              <p>
                Host Carer&apos;s become the primary carer to the person they
                are supporting, with the goal of creating a stable environment.
              </p>
              <Heading size={2} topMargin="standard">
                Could you be a Host Carer?
              </Heading>
              <p>
                There is the option of being a long term, short term and weekend
                respite Host Carer. And while Host Carer&apos;s come from all
                walks of life, cultures and age groups, they all need to be able
                to:
              </p>
              <ul>
                <li>
                  Develop an open and trusting relationship with the person
                  they’re supporting.
                </li>
                <li>
                  Provide a nurturing environment to promote the
                  individual&apos;s self-esteem and emotional wellbeing.
                </li>
                <li>
                  Facilitate activities that integrate the person with
                  disability with their family, community and culture.
                </li>
                <li>
                  Support the individual to participate in either school,
                  vocational training, employment or day activities.
                </li>
                <li>
                  Ensure the routine health and medical care of the person with
                  a disability.
                </li>
                <li>
                  Provide supports matched to the needs of the person, which may
                  include providing access to respite care and clinical support
                  from a range of therapeutic services.
                </li>
              </ul>
              <p>Host Carer’s receive:</p>
              <ul>
                <li>
                  Case management or casework support to coordinate the
                  different services involved in the placement.
                </li>
                <li>
                  A set reimbursement based on the service contract and
                  intensity of need of the person with disability. This will be
                  based on the contractual agreement with the relevant
                  government department.
                </li>
              </ul>
              <p>All Host Carer’s are thoroughly screened and assessed.</p>
            </Box>
            <Box className="w-100 w-third-l mt5 mt0-l">
              <ContactContents />
              <div className="mt4">
                <DisabilityForm
                  formId="disability-home-living"
                  formKind="disability-home-living"
                  formTitle="Contact Us"
                  hideNdisField
                />
              </div>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/woman-in-chair-with-petrabbit-Animoto2.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default DisabilityHostCare
